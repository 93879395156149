@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Light.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Regular.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Medium.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Bold.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}

@import url('https://fonts.cdnfonts.com/css/aharoni');

* {
  box-sizing: inherit;
  font-family: 'Noto Sans KR', 'Roboto', sans-serif !important;
}

/* customize ant design */
.ant-pagination {
  text-align: center;
}

.ant-table-pagination-right {
  justify-content: center;
}

.ant-form-item-label {
  overflow: visible;
}

.ant-form-item-required::before {
  color: #3785f7 !important;
  margin-right: 0 !important;
}

.ant-statistic-content-value,
.ant-statistic-content {
  font-size: 18px;
}
.ant-pagination-options {
  display: none;
}

/* customize react-pdf */
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__textContent {
  user-select: none;
}

.ant-badge-dot {
  background-color: #ff4d4f;
  border-radius: 50%;
  box-shadow: none;
}

.ant-badge {
  color: inherit;
}

.ant-notification {
  top: 60px !important;
  margin-right: 12px;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #a9a6a6;
  background-color: #e7e7e7;
}

.ant-btn-lg {
  font-weight: 500;
}

.ant-btn[disabled] {
  color: #a9a6a6;
}

.ant-modal-confirm {
  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-confirm-btns {
    float: none;
    text-align: center;

    .ant-btn {
      font-size: 12px;
      padding: 8px 22px;
      line-height: 18px;
      height: auto;
    }
  }
}

.ant-modal.clinicalTrial {
  .ant-modal-content {
    border-radius: 12px;
  }
}

// table
.ant-table-wrapper.secondary {
  table {
    border-collapse: collapse;

    tr.ant-table-row-selected > td,
    tr.ant-table-row:hover > td {
      background-color: #fff;
    }

    th.ant-table-cell {
      border-top: 2px solid #3785f7;
      padding: 4px;
      color: #4e5968;
    }
    td.ant-table-cell {
      padding: 12px 4px;
      font-weight: 500;
      color: #4e5968;
    }
  }
}

// drawer

.ant-drawer-content {
  overflow: visible;
}

@primary-color: #3785f7;@error-color: #ff4d4f;@link-color: #3785f7;@disabled-color: #e7e7e7;@border-radius-base: 4px;@screen-xs: 360px;@screen-sm: 600px;